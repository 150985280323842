import React from "react";
import { useWindowSize } from "react-use";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";

import { FormContainer } from "@/components/form/FormContainer";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { LogoCloud } from "@/components/LogoCloud";
import { Markdown } from "@/components/markdown";
import { Section } from "@/components/section";
import { Testimonials } from "@/components/Testimonials";
import { alignment } from "@/theme/alignment";
import { colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";

const PREFIX = "Form";

const classes = {
  section: `${PREFIX}-section`,
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
  form: `${PREFIX}-form`,
  headline: `${PREFIX}-headline`,
  text: `${PREFIX}-text`,
  checklist: `${PREFIX}-checklist`,
  markdown: `${PREFIX}-markdown`,
  image: `${PREFIX}-image`,
  checkmark: `${PREFIX}-checkmark`,
  testimonial: `${PREFIX}-testimonial`,
};

const FormStyled = styled("div", {
  shouldForwardProp: notForwarded(["styles"]),
})<{ styles: { theme: string; isFormTemplate: boolean } }>(
  ({ theme, styles }) => ({
    marginBottom: "3em",
    color: themes?.[styles.theme]?.color,
    [`& .${classes.section}`]: {
      ...alignment,
      margin: "0 auto",
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
      [theme.breakpoints.down(1000)]: {
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(6),
      },
    },
    [`& .${classes.container}`]: {
      display: "flex",
      justifyContent: "space-between",
      ...(styles.isFormTemplate
        ? {
            alignItems: "flex-start",
          }
        : {
            alignItems: "center",
          }),
      "& > *": {
        width: "calc(50% - 2.5em)",
      },
      [theme.breakpoints.down(1000)]: {
        flexDirection: "column",
        "& > *": {
          width: "100%",
        },
      },
    },
    [`& .${classes.content}`]: {
      "& > *:last-child": {
        marginBottom: 0,
      },
      [theme.breakpoints.down(1000)]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down(1000)]: {
          marginTop: 0,
        },
      },
    },
    [`& .${classes.headline}`]: {
      fontSize: 36,
      fontWeight: 600,
      margin: "0 0 .25em",
      ...(styles.isFormTemplate
        ? {
            marginTop: theme.spacing(5),
          }
        : {}),
      [theme.breakpoints.down(1200)]: {
        fontSize: 32,
      },
      [theme.breakpoints.down(1000)]: {
        marginTop: 0,
      },
      [theme.breakpoints.down(768)]: {
        fontSize: 28,
      },
    },
    [`& .${classes.text}`]: {
      fontSize: 25,
      margin: "0 auto",
      [theme.breakpoints.down(400)]: {
        fontSize: 18,
      },
    },
    [`& .${classes.markdown}`]: {
      marginTop: theme.spacing(1.5),
    },
    [`& .${classes.image}`]: {
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(1.5, "auto"),
      maxWidth: "100%",
    },
    [`& .${classes.checklist}`]: {
      fontSize: 20,
      listStyle: "none",
      margin: "1.5em 0 0",
      padding: 0,
      "& li": {
        display: "block",
        margin: "0 0 .75em",
        paddingLeft: 45,
        position: "relative",
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
    },
    [`& .${classes.checkmark}`]: {
      alignItems: "center",
      backgroundColor: themes?.[styles.theme]?.checklistBackground,
      borderRadius: "50%",
      color: "transparent",
      display: "flex",
      fontSize: 11,
      height: 30,
      justifyContent: "center",
      left: 0,
      marginRight: "1em",
      position: "absolute",
      textShadow: themes?.[styles.theme]?.checklistColor,
      top: "50%",
      transform: "translateY(-50%)",
      width: 30,
    },
    [`& .${classes.testimonial}`]: {
      backgroundColor: themes?.[styles.theme]?.testimonialBackground,
      borderRadius: 8,
      color: colors.white01,
      marginTop: "3.5em",
      padding: "2em",
    },
    [`& .${classes.form}`]: {
      backgroundColor: colors.white01,
      borderRadius: 8,
      boxShadow: theme.shadows[6],
      color: colors.gray12,
      padding: "2em",
    },
  })
);

const themes = {
  dark: {
    backgroundImage: "/images/form-dark.png",
    checklistBackground: colors.mintGreen,
    checklistColor: `0 0 0 ${colors.white01}`,
    color: colors.white01,
    testimonialBackground: "rgba(255,255,255, .025)",
    testimonialColor: colors.white01,
  },
  light: {
    backgroundImage: "/images/form-light.png",
    checklistBackground: colors.mintGreen,
    checklistColor: `0 0 0 ${colors.white01}`,
    color: colors.gray11,
    testimonialBackground: colors.bluePurple,
    testimonialColor: colors.white01,
  },
};

export interface FormProps {
  className: string;
  form: GraphQLQuery;
  type: "light" | "dark";
  isFormTemplate: boolean;
}

const Form = ({ className, form, isFormTemplate }: FormProps) => {
  const { width } = useWindowSize();
  const theme = form.theme || "dark";

  const bottomContainer = (
    <>
      {form.logoSection ? (
        <LogoCloud
          title={form.logoSection.title}
          logos={form.logoSection.logos}
          sx={{ marginBottom: 0 }}
        />
      ) : null}
      {form.testimonialSection ? (
        <Testimonials
          className={classes.testimonial}
          ids={[form.testimonialSection]}
          variant="secondary"
        />
      ) : null}
    </>
  );

  return (
    <FormStyled className={className} styles={{ theme, isFormTemplate }}>
      <Section
        backgroundImage={themes?.[theme]?.backgroundImage}
        className={classes.section}
        color={themes?.[theme]?.color}
      >
        <div className={classes.container}>
          <div className={classes.content}>
            {form.headline ? (
              <Typography
                variant={isFormTemplate ? "h1" : "h3"}
                className={classes.headline}
              >
                {form.headline}
              </Typography>
            ) : null}
            {form.text ? (
              <Typography variant="h6" className={classes.text}>
                {form.text}
              </Typography>
            ) : null}
            {form.markdownSection ? (
              <Markdown
                className={classes.markdown}
                html={form.markdownSection}
              />
            ) : null}
            {form.imageSection ? (
              <GatsbyImage
                alt={form.headline ?? "prismatic form"}
                className={classes.image}
                image={form.imageSection.childImageSharp.gatsbyImageData}
                loading="eager"
              />
            ) : null}
            {form.checklistSection ? (
              <ul className={classes.checklist}>
                {form.checklistSection.map((item, itemIdx) => (
                  <li key={itemIdx}>
                    <span className={classes.checkmark}>✔️</span>
                    {item}
                  </li>
                ))}
              </ul>
            ) : null}
            {width >= 1000 ? bottomContainer : null}
          </div>
          <FormContainer className={classes.form} {...form} />
          {width < 1000 ? bottomContainer : null}
        </div>
      </Section>
    </FormStyled>
  );
};

export default Form;

import React from "react";
import { styled } from "@mui/material/styles";

import Form from "@/components/form";
import Layout from "@/layout";

const PREFIX = "FormPage";

const classes = {
  form: `${PREFIX}-form`,
};

const FormPageStyled = styled("div")(({ theme }) => ({
  [`& .${classes.form}`]: {
    marginBottom: 0,
    marginTop: theme.spacing(12),
  },
}));

const FormPage = ({ children, form, html, minimalNavigation, useScroll }) => {
  return (
    <Layout
      lightBackground
      manualBanner={Boolean(html)}
      minimalNavigation={minimalNavigation}
      noStick={Boolean(html)}
      useScroll={useScroll !== false}
    >
      <FormPageStyled>
        <Form form={form} className={classes.form} isFormTemplate />
        {children}
      </FormPageStyled>
    </Layout>
  );
};

export default FormPage;
